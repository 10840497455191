import { MenuPlacement, MenuPosition } from "react-select";
import CreatableSelect from "react-select/creatable";
import Spinner from "views/components/spinners/Spinner";

type propsTypes = {
  label?: string;
  data: {
    value: string;
    label: string;
  }[];
  selectedValue?: any;
  placeholder?: string;
  defaultValueNumber?: number;
  menuPlacement?: MenuPlacement;
  menuPosition?: MenuPosition;
  invalid?: boolean;
  invalidMessage?: string;
  disabled?: boolean;
  onChange: (selectedValue: any) => void;
  formatOptionLabel?: any;
  graySelectOption?: boolean;
  filterScore?: boolean;
  isClearable?: boolean;
  priceSelect?: boolean;
  containerClassName?: string;
  loadingOptions?: boolean;
  components?: any;
  isSearchable?: boolean;
  filtersSelect?: boolean;
  hideSelectedOptions?: boolean;
  menuWidth?: string;
  customRef?: any;
  customSingleValue?: any;
  hoverControl?: string;
  customDropdownIndicator?: any
};

const SingleSelect = (props: propsTypes) => {
  return (
    <div
      className={`flex flex-col w-full ${
        props.containerClassName ? props.containerClassName : ""
      } relative`}
    >
      {props.label && (
        <p className="text-dark-gray text-sm font-light mb-1.5 select-none">
          {props.label}
        </p>
      )}
      <div className={"relative h-full"}>
        <CreatableSelect
          ref={props?.customRef || undefined}
          isValidNewOption={() => false}
          components={{
            ...(props.components && { Option: props.components }),
            ...(props.customSingleValue && {
              SingleValue: props.customSingleValue,
            }),
            ...(props.customDropdownIndicator && { DropdownIndicator: props.customDropdownIndicator }),
          }}
          isClearable={props.isClearable}
          options={props.data}
          isDisabled={props.disabled}
          isSearchable={props.isSearchable}
          hideSelectedOptions={props.hideSelectedOptions || false}
          placeholder={props.loadingOptions ? "Loading..." : props.placeholder}
          onChange={(value: any) => props.onChange(value)}
          value={props.selectedValue?.value ? props.selectedValue : null}
          defaultValue={
            props.defaultValueNumber
              ? props.data[props.defaultValueNumber - 1]
              : ""
          }
          menuPlacement={props.menuPlacement}
          menuPosition={props.menuPosition ? props.menuPosition : "fixed"}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#F1F1F1",
              primary: "var(--main-color)",
            },
          })}
          formatOptionLabel={
            props.formatOptionLabel ? props.formatOptionLabel : null
          }
          menuPortalTarget={document.body}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...(props.priceSelect && {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                borderRight: "none",
                // minHeight: 47,
                width: "max-content",
              }),
              cursor: props.disabled ? "default" : "pointer",
              borderRadius: 10,
              border: props.filtersSelect ? "none" : "",
              borderColor: props.invalid
                ? "#CD4C4C"
                : props.disabled
                  ? "#A3A3A3"
                  : props.filtersSelect
                    ? "none"
                    : props.graySelectOption
                      ? "#F1F1F1"
                      : state.menuIsOpen
                        ? "var(--main-color)"
                        : "#A3A3A3",
              "&:hover": {
                background: props.hoverControl || "transparent",
              },
              backgroundColor: props.invalid
                ? "#CD4C4C1A"
                : props.disabled
                  ? "#F2F2F2"
                  : props.filtersSelect
                    ? "#F6F6F6"
                    : props.graySelectOption
                      ? "#F1F1F1"
                      : state.menuIsOpen
                        ? "initial"
                        : "initial",
              boxShadow: "none",
              height: props.filtersSelect
                ? `29px`
                : props.priceSelect
                  ? "100%"
                  : 46.6,
              minHeight: props.filtersSelect ? `29px` : "",
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              height: props.filtersSelect ? "29px" : "",
              paddingRight: props.filtersSelect ? "0px" : "",
              paddingTop: props.filtersSelect ? "0px" : "",
              marginTop: props.filtersSelect ? "-3px" : "",
              fontSize: props.filtersSelect ? "14px" : "",
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              height: props.filtersSelect ? "29px" : "",
              padding: props.filtersSelect ? "0px" : "",
              width: props.filtersSelect ? "30px" : "",
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              fontSize: props.filtersSelect ? "14px" : "",
              marginTop: props.filtersSelect ? "-2px" : "",
              color: props.graySelectOption ? "#9D9D9D" : baseStyles.color,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              borderColor: "#1A1A1A",
              height: "100%",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 10,
              padding: 5,
              backgroundColor: props.filterScore ? "#F1F1F1" : "#1A1A1A",
              boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
              ...(props.priceSelect && {
                width: "max-content",
              }),
              ...(props.menuWidth && {
                width: props.menuWidth,
              }),
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: 10,
              marginBottom: 5,
              cursor: "pointer",
              backgroundColor:
                props.filterScore && state.isFocused
                  ? "#bfbfbf63"
                  : state.isFocused
                    ? "#3B3B3B"
                    : props.filterScore
                      ? "#F1F1F1"
                      : "#1A1A1A",
              color: "white",
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "transparent",
              color: "transparent",
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              ...(props.priceSelect && {
                padding: 0,
              }),
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              ...(props.priceSelect && {
                width: "min-content",
                overflow: "hidden",
              }),
            }),
            menuPortal: (baseStyles) => ({
              ...baseStyles,
              zIndex: 51,
            }),
            menuList: (base) => ({
              ...base,
              "::-webkit-scrollbar": {
                width: "1px",
                height: "0px",
              },
              "::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#aaa",
              },
              "::-webkit-scrollbar-thumb:hover": {
                background: "#aaa",
              },
            }),
          }}
        />
        {props.loadingOptions ? (
          <div className={"absolute -right-6 top-2"}>
            <Spinner classIcon={"w-[20px] h-[20px]"} />
          </div>
        ) : null}
      </div>
      {props.invalid && props.invalidMessage && (
        <p className="text-invalid-red text-sm font-light mt-1.5">
          {props.invalidMessage}
        </p>
      )}
    </div>
  );
};

export default SingleSelect;
