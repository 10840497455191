import { Fragment, RefObject, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import zenhire from "assets/icons/zenhire-ico.png";
import notificationIco from "assets/icons/notification-ico.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-green-down.svg";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import Avatar from "../Avatar/Avatar";
import Spinner from "../spinners/Spinner";
import { useOutsideClickHandler } from "../../../common/hooks/useOutsideClickHandler";
import { useAuthorization } from "../../../common/hooks/useAuthorization";
import { useLocation, useNavigate } from "react-router";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { ReactComponent as ArrowDownDropdown } from "assets/icons/arrow-down-3.svg";

const AdminNavbar = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const userData = useAppSelector((state) => state.authentication.userData);
  const navigate = useNavigate();

  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  const { checkPermissions } = useAuthorization();

  const ref: RefObject<HTMLDivElement> = useRef(null);
  const closeDropdownHandler = () => {
    setIsProfileDropdownOpen(false);
  };

  function closeMenu() {
    setTimeout(() => {
      closeDropdownHandler();
    }, 50);
  }

  const handleClickOutside = (event?: any) => {
    closeMenu();
  };

  useOutsideClickHandler(ref, handleClickOutside);

  const displayStyle = () => {
    return "relative z-50 block bg-black";
  };

  const handleTestRedirect = () => {
    if (checkPermissions("GET_TESTS_PUBLISHED")) {
      return "/test-library/published";
    } else if (checkPermissions("GET_TESTS_INCUBATION")) {
      return "/test-library/incubator";
    } else if (checkPermissions("GET_TESTS_DRAFT")) {
      return "/test-library/drafts";
    }
  };

  const navLinks = [
    {
      label: "Dashboard",
      to: "/",
    },
    {
      label: "Test Library",
      functionRedirect: () => handleTestRedirect(),
      to: handleTestRedirect(),
      permissions: [
        "GET_TESTS",
        "GET_TESTS_PUBLISHED",
        "GET_TESTS_DRAFT",
        "GET_TESTS_INCUBATION",
      ],
    },
    {
      label: "Users",
      to: "/users",
      permissions: "GET_USERS",
    },
    {
      label: "Applicants",
      to: "/applicants",
      permissions: "GET_APPLICANTS",
    },
    {
      label: "Competencies",
      to: "/microdimensions",
      permissions: "GET_MICRODIMENSIONS",
    },
    {
      label: "Positions",
      activeLink: location?.pathname?.startsWith(`/positions`),
      to: "/positions",
      permissions: "GET_POSITIONS",
      children: !checkPermissions("VIEW_POSITION_TEMPLATES")
        ? []
        : [
            {
              label: "Positions List",
              functionOption: () => navigate(`/positions`),
              permissions: "GET_POSITIONS",
              checked: location?.pathname === `/positions`,
            },
            {
              label: "Templates List",
              functionOption: () => navigate(`/positions/templates`),
              permissions: "VIEW_POSITION_TEMPLATES",
              checked: location?.pathname === `/positions/templates`,
            },
          ],
    },

    {
      label: "Companies",
      to: "/companies",
      permissions: "GET_COMPANY",
    },
    {
      label: "Emails",
      to: "/system-emails",
      permissions: "SYSTEM_EMAIL_TEMPLATE_GET",
    },
  ];

  return (
    <>
      <div
        className={`${displayStyle()} bg-black`}
        ref={ref}
        id="adminNavbarID"
      >
        <div className="flex items-center m-auto h-[46px] px-[20px]">
          <NavLink
            to={"/"}
            className="flex items-center cursor-pointer gap-2 mr-auto"
            onClick={() => {
              setIsProfileDropdownOpen(false);
            }}
          >
            <img
              src={zenhire}
              className="w-[30px] h-auto"
              alt={"zenhire-logo"}
            />
            <p className="text-white text-[17px]">ZenHire Admin</p>
          </NavLink>

          <nav className="text-dimmed-white text-[13px] font-[500] flex h-full">
            {navLinks
              .filter((link) =>
                (link.permissions && checkPermissions(link.permissions)) ||
                !link.permissions
                  ? link
                  : null
              )
              .map((link, index) =>
                link.children?.length ? (
                  <Fragment key={index}>
                    <CustomDropdown
                      customToggler={
                        <Link
                          to={link.to}
                          key={index}
                          className={`h-full py-[10px] px-3.5 client-nav-link flex items-center relative cursor-pointer justify-center gap-2 ${
                            link.activeLink
                              ? "zh-active-menu-link text-white"
                              : ""
                          }`}
                        >
                          {link.label}
                          <ArrowDownDropdown
                            className={`group-hover:[&_path]:fill-white mt-[1px] ${link.activeLink ? "[&_path]:fill-white" : "[&_path]:fill-dimmed-white"}`}
                          />
                          <div
                            className={
                              "zh-active-menu-link-border hidden border-b-[2px] border-white absolute bottom-0.5 left-0 w-full"
                            }
                          ></div>
                        </Link>
                      }
                      functionOption={link.children.filter((link) =>
                        (link.permissions &&
                          checkPermissions(link.permissions)) ||
                        !link.permissions
                          ? link
                          : null
                      )}
                      menuClassName={`!mt-[7px]`}
                      toggleOnClick={false}
                    />
                  </Fragment>
                ) : (
                  <NavLink
                    key={index}
                    to={
                      link.functionRedirect
                        ? link.functionRedirect()
                        : (link.to as any)
                    }
                    className={({ isActive }) =>
                      `py-[10px] px-3.5 client-nav-link flex items-center relative hover:text-white ${
                        isActive ||
                        (location.pathname.includes("test-library") &&
                          link?.to?.includes("test-library"))
                          ? "zh-active-menu-link text-white"
                          : ""
                      }`
                    }
                    end={!link.to}
                    onClick={() => {
                      setIsProfileDropdownOpen(false);
                    }}
                  >
                    {link.label}
                    <div
                      className={
                        "zh-active-menu-link-border hidden border-b-[2px] border-white absolute bottom-0.5 left-0 w-full"
                      }
                    ></div>
                  </NavLink>
                )
              )}
          </nav>

          <div className="relative flex items-center gap-5 ml-7">
            <img
              src={notificationIco}
              className="cursor-pointer select-none w-[25px] h-auto"
              alt="notificaton-ico"
            />
            {userData.loading || userData.processing || !userData.value ? (
              <Spinner classIcon={"w-[30px] h-[30px]"} />
            ) : (
              <>
                <div
                  className="cursor-pointer w-[30px] h-[30px]"
                  onClick={() => {
                    setIsProfileDropdownOpen((prevState) => !prevState);
                  }}
                >
                  <Avatar
                    content={`${userData.value.name} ${userData.value.surname}`}
                    avatar={userData.value.avatar}
                    size={30}
                  />
                </div>

                {isProfileDropdownOpen && (
                  <div
                    className="absolute top-10 right-0 z-50"
                    onMouseLeave={closeDropdownHandler}
                  >
                    <ProfileDropdown
                      img={userData.value.avatar}
                      closeDropdown={closeDropdownHandler}
                      contentName={`${userData.value.name} ${userData.value.surname}`}
                      role={userData.value.role.description}
                      fullName={`${userData.value.name} ${userData.value.middleName} ${userData.value.surname}`}
                      settingsLink={"/profile-settings/general"}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNavbar;
