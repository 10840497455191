import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "common/api/http";
import { formatFilterDate } from "common/utility/Utils";
import { setToast } from "redux/alert";
import { RootState } from "redux/store";
import { updateInboxCount } from "../../../../redux/global";

function switchInboxType(inboxType: string, id?: string) {
  switch (inboxType) {
    case "APPLICATION":
      return `applications/${id}`;
    case "PERSONAL":
      return "users";
    case "COMPANY":
      return "companies";
    default:
      return "";
  }
}

interface getThreadsParams {
  page?: number;
  currentLocation?: string;
  companyId?: string;
  paramsArray?: any;
  signal?: any;
  typeInbox: string;
}

export const getInbox = createAsyncThunk<
  any,
  getThreadsParams,
  { state: RootState }
>(
  "company/getInbox",
  async (
    { page, companyId, paramsArray, signal, typeInbox },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const params = {
        limit: 15,
        page: page ? page : 1,
        ...(companyId && {
          company: companyId,
        }),
        ...(paramsArray?.filters?.read !== undefined &&
          Boolean(paramsArray.filters.read) && {
            read: paramsArray.filters.read,
          }),
        ...(paramsArray?.search && {
          email: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.star && {
          star: true,
        }),
        ...(paramsArray?.filters?.sent !== undefined &&
          Boolean(paramsArray.filters.sent) && {
            sent: paramsArray.filters.sent,
          }),
        ...(paramsArray?.filters?.from_date && {
          dateFrom: formatFilterDate(paramsArray?.filters?.from_date),
        }),
        ...(paramsArray?.filters?.to_date && {
          dateTo: formatFilterDate(paramsArray?.filters?.to_date),
        }),
        ...(paramsArray?.filters?.applicant && {
          applicantId: paramsArray.filters.applicant._id
            ? `${paramsArray.filters.applicant._id}`
            : "null",
        }),
        ...(paramsArray?.filters?.position?.value && {
          positionId: paramsArray?.filters?.position.value,
        }),
      };
      // GET /email/applications/:applicationId/inbox
      const response = await http.get(
        `/email/${switchInboxType(typeInbox?.toUpperCase(), paramsArray?.filters?.applicationId)}/inbox`,
        {
          params,
          signal,
        },
      );

      const existingValues = getState().inbox.inboxThreads.value;
      const newValues =
        existingValues && response.data?.page && response.data.page > 1
          ? {
              ...response.data,
              data: [...existingValues.data, ...response.data.data],
            }
          : response.data;
      return newValues;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
        position: "INBOX_THREADS",
      });
    }
  },
);

export const getInboxCount = createAsyncThunk<
  any,
  { companyId?: string },
  { state: RootState }
>("company/getInboxCount", async ({ companyId }, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `/email/inboxes/statuses${companyId ? `?company=${companyId}` : ""}`,
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue({
      ...error.data,
      code: error.code,
      method: error.config.method,
      type: "ACTION",
      style: "TOAST",
    });
  }
});

export const getThread = createAsyncThunk<
  any,
  {
    threadId: string;
    unread?: boolean;
  },
  { state: RootState }
>(
  "company/getInboxThread",
  async ({ threadId, unread }, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.get(`/email/thread/${threadId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
        position: "INBOX_THREAD",
      });
    }
  },
);

export const starThread = createAsyncThunk<
  any,
  {
    threadId: string;
  },
  { state: RootState }
>("company/starThread", async ({ threadId }, { rejectWithValue, getState }) => {
  try {
    const response = await http.post(`/email/threads/${threadId}/star`);

    // add or remove star depending on the action
    const thread = getState().inbox.inboxThread.value;
    const userId = getState().authentication.userData.value?._id;
    if (!thread || !userId) return null;

    if (thread.stars.includes(userId)) {
      thread.stars = thread.stars.filter((star) => star !== userId);
    } else thread.stars.push(userId);

    return thread.stars;
  } catch (error: any) {
    return rejectWithValue({
      ...error.data,
      code: error.code,
      method: error.config.method,
      type: "ACTION",
      style: "TOAST",
    });
  }
});

type customFileType = {
  fileName: string;
  contentType: string;
  size: number;
  key: string;
};

export const sendNewMessage = createAsyncThunk<
  any,
  {
    to: string;
    subject: string;
    htmlContent: string;
    attachments: Array<File>;
    senderType: string;
    applicantId?: string;
    asReply?: boolean;
    threadId?: string;
    lastMessageId?: string;
  },
  { state: RootState }
>(
  "company/sendNewMessage",
  async (
    {
      to,
      subject,
      htmlContent,
      attachments,
      senderType,
      applicantId,
      asReply,
      threadId,
      lastMessageId,
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const formData = new FormData();

      if (asReply) {
        formData.append("htmlContent", htmlContent);
        if (attachments?.length) {
          attachments.forEach((attachment) => {
            formData.append(`attachments`, attachment);
          });
        }
      } else {
        formData.append("to", to);
        formData.append("subject", subject);
        formData.append("htmlContent", htmlContent);
        if (attachments?.length) {
          attachments.forEach((attachment) => {
            formData.append(`attachments`, attachment);
          });
        }
        if (applicantId) {
          formData.append("applicantId", applicantId);
        }
      }

      const formatEp = `/email/${asReply ? `threads/${threadId}/message/${lastMessageId}/reply${senderType === "COMPANY" ? "/company" : ""}` : `send${senderType === "COMPANY" ? "/company" : ""}`}`;
      // /email/threads/:threadId/message/:messageId/reply
      // /email/threads/:threadId/message/:messageId/reply/company

      const response = await http.post(formatEp, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(
        updateInboxCount({
          inboxType:
            senderType === "COMPANY"
              ? "companyInboxCount"
              : "personalInboxCount",
          typeValue: "sent",
          value: 1,
        }),
      );
      dispatch(
        updateInboxCount({
          inboxType:
            senderType === "COMPANY"
              ? "companyInboxCount"
              : "personalInboxCount",
          typeValue: "all",
          value: 1,
        }),
      );

      dispatch(
        setToast({
          message: "Message successfully send",
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const downloadAttachment = createAsyncThunk<
  any,
  { filename: string; contentType: string; key: string },
  { state: RootState }
>(
  "position/downloadAttachment",
  async ({ filename, contentType, key }, { rejectWithValue }) => {
    try {
      const response: any = await http.get(`/email/attachments/${key}`, {
        responseType: "arraybuffer",
      });

      // const link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // link.download = `${filename}`;

      // document.body.appendChild(link);

      // link.setAttribute("target", "_blank");
      // link.click();
      // link.remove();

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

// Sync Emails
export const syncEmails = createAsyncThunk<
  any,
  {
    typeEmail: string;
    paramsArray?: any;
    companyId?: string;
  },
  { state: RootState }
>(
  "company/syncEmails",
  async (
    { typeEmail, paramsArray, companyId },
    { rejectWithValue, getState },
  ) => {
    try {
      const params = {
        limit: 15,
        page: 1,
        ...(companyId && {
          company: companyId,
        }),
        ...(paramsArray?.filters?.read !== undefined &&
          Boolean(paramsArray.filters.read) && {
            read: paramsArray.filters.read,
          }),
        ...(paramsArray?.search && {
          email: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.star && {
          star: true,
        }),
        ...(paramsArray?.filters?.sent !== undefined &&
          Boolean(paramsArray.filters.sent) && {
            sent: paramsArray.filters.sent,
          }),
        ...(paramsArray?.filters?.from_date && {
          dateFrom: formatFilterDate(paramsArray?.filters?.from_date),
        }),
        ...(paramsArray?.filters?.to_date && {
          dateTo: formatFilterDate(paramsArray?.filters?.to_date),
        }),
        ...(paramsArray?.filters?.applicant && {
          applicantId: paramsArray.filters.applicant._id
            ? `${paramsArray.filters.applicant._id}`
            : "null",
        }),
        ...(paramsArray?.filters?.position?.value && {
          positionId: paramsArray?.filters?.position.value,
        }),
      };

      const response: any = await http.post(
        `/email/${typeEmail}/inbox/sync`,
        {},
        {
          params: params,
        },
      );

      const existingValues = getState().inbox.inboxThreads.value;
      const newValues =
        existingValues && response.data?.page && response.data.page > 1
          ? {
              ...response.data,
              data: [...existingValues.data, ...response.data.data],
            }
          : response.data;
      return newValues;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
        position: "INBOX_THREADS",
      });
    }
  },
);
